<template>
    <div :style="`direction:rtl`">
      <!-- <b-button v-b-modal.modal-prevent-closing>Open Modal</b-button> -->
      <b-modal
        id="modal-prevent-closing"
        ref="modal"
        :title="$parent.lang.discount"
        ok-only
        @ok="handleOk"
        :ok-title="$parent.lang.close"
        :style="`direction:rtl`"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <v-row :style="`direction:rtl`">
            <v-col cols="12" md="7" :style="`direction:rtl`">
              <label>{{$parent.lang.discount}}</label>
              <b-form-input
                  ref="discountAmount"
                  :label="$parent.lang.discount"
                  v-model="$parent.discount" 
                  class="inborder"
                  @change="checkDiscoutn()"
                  :readonly="$parent.discount_type != 1"
              />
            </v-col>
            <v-col cols="12" md="5" :style="`direction:rtl`">
              <label>{{$parent.lang.discountType}}</label>
              <b-form-select
                  v-model="$parent.discount_type"
                  :options="discountTypes" 
                  class="selborder"
                  @change="chDiscount()"
              />
            </v-col>
          </v-row>
            
        </form>
      </b-modal>
    </div>
  </template>
  
  <script>
    export default {
      data() {
        return {
          name: '',
          nameState: null,
          submittedNames: [],
          discountTypes: [
            {text: 'خصم عروض', value: 1},
            {text: 'ضيافة نورا', value: 2},
            {text: 'ضيافة هيا', value: 3},
            {text: 'ضيافة محمد', value: 4},
          ]
        }
      },
      methods: {
        chDiscount(){
          if(this.$parent.discount_type == 1){
            this.$parent.discount = 0;
          }else{
            this.$parent.discount = this.$parent.intotal;
          }
        },
        checkDiscoutn(){
            
            if(this.$parent.discount > this.$parent.intotal)
            {
                this.$parent.discount = this.$parent.intotal;
            }
        },
        checkFormValidity() {
        //   const valid = this.$refs.form.checkValidity()
        //   this.nameState = valid
        //   return valid
        },
        resetModal() {
        //   this.name = ''
        //   this.nameState = null
        },
        handleOk(bvModalEvent) {
          // Prevent modal from closing
        //   bvModalEvent.preventDefault()
          // Trigger submit handler
        //   this.handleSubmit()
        },
        handleSubmit() {
          // Exit when the form isn't valid
        //   if (!this.checkFormValidity()) {
        //     return
        //   }
          // Push the name to submitted names
        //   this.submittedNames.push(this.name)
          // Hide the modal manually
        //   this.$nextTick(() => {
        //     this.$bvModal.hide('modal-prevent-closing')
        //   })
        }
      }
    }
  </script>